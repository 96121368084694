
.dataAnalysis-container {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 100px);
    .top-wrapper {
        position: relative;
        .el-button {
            position: absolute;
            top: -9px;
            right: 0;
        }
    }
    .dataAnalysis-item {
        background: #fff;
        margin-top: 20px;
        border-radius: 10px;
        padding: 30px 40px;
        box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
        .item-top {
            font-size: 18px;
            line-height: 1;
            padding-left: 16px;
            color: #3A3567;
            position: relative;
            padding-bottom: 20px;
            border-bottom: 1px solid #ddd;
            &:before {
                content: '';
                position: absolute;
                width: 8px;
                height: 15px;
                background: #9ACC5B;
                border-radius: 4px;
                left: 0;
            }
        }
        .item-content {
            padding-top: 10px;
            ::v-deep img {
                max-width: 100%;
            }
            ::v-deep a {
                color: #1D33E1;
                &:hover {
                    color: #5F55F1;
                }
            }
            ::v-deep table {
                border-collapse: collapse!important;
                width: 100%!important;
                border-color: transparent;
                tr {
                    &:nth-child(even) {
                        td {
                            background: #fff;
                        }
                    }
                    &:nth-child(odd) {
                        td {
                            background: #E3E2F4;
                        }
                    }
                    th {
                        text-align: center;
                        padding: 12px 20px;
                        height: 20px!important;
                        line-height: 20px;
                        box-sizing: border-box;
                        font-size: 16px;
                        color: #fff;
                        white-space: nowrap;
                        background: #5F55F1;
                        font-weight: normal;
                    }
                    td {
                        text-align: center;
                        padding: 12px 20px;
                        font-size: 16px;
                        color: #3A3567;
                    }
                }
            }
        }
    }
}
